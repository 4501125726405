<template>
<div id="Pictures">
  <div id="pictures-container">
    <template v-for="i in [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]">
      <div :class="'picture pic_' + i"></div>
    </template>
  </div>
</div>
</template>

<script>
import { onMounted } from 'vue';

export default {
  name: 'Pictures',
  setup () {

    onMounted(() => {
      let items = document.querySelectorAll('.picture');
      ;[...items].forEach(item => {
        item.onmouseenter = () => {
          if (item.previousElementSibling) {
            item.previousElementSibling.classList.add('sibling');
          }
          if (item.nextElementSibling) {
            item.nextElementSibling.classList.add('sibling');
          }
        };

        item.onmouseleave = () => {
          if (item.previousElementSibling) {
            item.previousElementSibling.classList.remove('sibling');
          }
          if (item.nextElementSibling) {
            item.nextElementSibling.classList.remove('sibling');
          }
        };
      });


    });
  },
};
</script>

<style lang="stylus">
#Pictures
  //background-color #f5f5fa
  position absolute
  overflow hidden
  z-index 100
  top 70px
  right 70px
  bottom 70px
  left 70px
  border-radius 20px
  display flex
  align-items center
  justify-content center
  box-shadow: 0 10px 30px 0 rgba(37, 44, 97, 0.15), 0 4px 18px 0 rgba(93, 100, 148, 0.2);
  background-image: linear-gradient(#fff, #f5f5fa);
  #pictures-container
    height 200px
    margin 0 auto
    width 80%
    display flex
    border-radius 15px
    background #FCFCFD
    box-shadow: 0 2px 4px rgba(45, 35, 66, 0.4), 0px 7px 13px -3px rgba(45, 35, 66, 0.3), inset 0px -3px 0px #D6D6E7;
    .picture
      transition flex 0.5s, filter .2s, transform .2s
      background-position center
      background-size cover
      overflow hidden
      align-items center
      flex 1
      z-index 1
      filter blur(2px)
      margin -2px // to eliminate the white lines between pictures
      &:first-child
        border-radius 15px 0 0 15px
      &:last-child
        border-radius 0 15px 15px 0
      &:hover
        flex 4 !important
        transform scale(1.5)
        filter blur(0)
        z-index 20
      &.sibling
        flex 2
        transform scale(1.2)
        filter blur(1px)
        z-index 2

for $num in (10..30)
  .pic_{$num}
    background-image url("../../public/unsplash/pic_" + $num + ".jpg");
</style>